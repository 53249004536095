import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Container, Table, Modal, InputGroup, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { omit } from 'lodash';
import {
    isTokenValid, getUserAccessById, findProjectForEngineHealthInspection, findStaffForEngineHealthInspection,
    findEngineByVesselId, insertUpdateEngineHealthInspectionDetail, findEngineHealthInspectionById,
    findProjectForEngineHealthInspectionById, findStaffForEngineHealthInspectionById, findEngineForEngineHealthInspectionById,
    findAllMechanicGroupForEngineHealthInspection, findMechanicGroupForEngineHealthInspectionById, findStaffByFieldStaff, findAllInspectionDetail, getDocumentEngineHealthInspection
} from "../../../../Helpers/ApplicationHelpers";
import "../../../../App.css";
import Navbar from "../../../../Components/NavBar";
import ContainerBox from "../../../../Components/ContainerBox";
import FormSelect from "../../../../Components/FormSelect";
import Loading from "../../../../Components/Loading";
import { Editor } from '@tinymce/tinymce-react';
import TextBox from "../../../../Components/Textbox";
import { Trash, ArrowLeft, Clock, ArrowClockwise, Search, XSquareFill, PencilFill, CameraFill, PrinterFill, Cameraplus, Image, FilePlusFill, FileCheckFill, Newspaper } from "react-bootstrap-icons";
import moment from 'moment';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import Paginations from "../../../../Components/Pagination";
import { convert } from 'html-to-text'
import TroubleshootingDetail from "../../../../Components/TroubleshottingDetail";
import TroubleshootingEquipment from "../../../../Components/TroubleshootingEquipment";
import EngineHealthInspectionDetail from "../../../../Components/EngineHealthInspectionDetail";
import * as fs from "fs";
// import { Document, Packer, Paragraph, Tab, TextRun } from "docx";
import { saveAs } from 'file-saver';
import { Buffer } from "buffer";

export default function EngineHealtInspectionDetailPage() {
    const [cookies, setCookie] = useCookies(["token"]);
    const navigate = useNavigate();
    const location = useLocation();
    const [userAccess, setUserAccess] = useState({
        allowCreate: false,
        allowDelete: false,
        isLoadAccessFinished: false,
    });
    const [engineHealthInspectionId, setEngineHealthInspectionId] = useState("");
    const [isPageInitialized, setIsPageInitialized] = useState(false);
    const [inputTestResultValue, setInputTestResultValue] = useState({});
    const [isTestResultValueAdded, setIsTestResultValueAdded] = useState(false);
    const [listProject, setListProject] = useState({
        data: [],
        isFinishedLoading: false
    });
    const [listStaff, setListStaff] = useState({
        data: [],
        isFinishedLoading: false
    });
    const [listStaffForNewCrew, setListStaffForNewCrew] = useState({
        data: [],
        isFinishedLoading: false
    });
    const [searchProjectParameters, setSearchProjectParameters] = useState({
        isProjectNameChecked: true,
        isVesselNameChecked: false,
        isProjectNumberChecked: false,
        isCustomerNameChecked: false,
        projectName: "",
        vesselName: "",
        projectNumber: "",
        customerName: "",

    });

    const [inspDetailFromComponent, setInspDetailFromComponent] = useState();


    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

    const [searchStaffForNewCrew, setSearchStaffForNewCrew] = useState({
        isStaffNameChecked: true,
        isEmployeeNumberChecked: false,
        isStaffDivisionChecked: false,
        staffName: "",
        employeeNumber: "",
        staffDivision: "",
    });

    const [searchStaffParameters, setSearchStaffParameters] = useState({
        isStaffNameChecked: true,
        isEmployeeNumberChecked: false,
        isStaffDivisionChecked: false,
        staffName: "",
        employeeNumber: "",
        staffDivision: "",

    });

    const [addingNewInspectionDetailButton, setAddingNewInspectionDetailButton] = useState(false);
    const [isStaffForCrewLoading, setIsStaffForCrewLoading] = useState(false);
    const [isEditDetail, setIsEditDetail] = useState(false);
    const [shouldLoadStaffForCrewFromAPI, setShouldLoadStaffForCrewFromAPI] = useState(false);
    const [shouldLoadProjectFromAPI, setShouldLoadProjectFromAPI] = useState(false);
    const [isProjectLoading, setIsProjectLoading] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [shouldLoadStaffFromAPI, setShouldLoadStaffFromAPI] = useState(false);
    const [isStaffLoading, setIsStaffLoading] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [selectedStaffForNewCrew, setSelectedStaffForNewCrew] = useState(null);
    const [listSelectedStaffForNewCrew, setListSelectedStaffForNewCrew] = useState([]);
    const [isSelectProjectModalVisible, setIsSelectProjectModalVisible] = useState(false);
    const [isSelectStaffModalVisible, setIsSelectStaffModalVisible] = useState(false);
    const [isAddNewCrewModalVisible, setIsAddNewCrewModalVisible] = useState(false);
    const [engineHealthInspectionDetail, setEngineHealthInspectionDetail] = useState({
        crew: [],
        details: [],
        isFinishedLoading: false,
        shouldLoadFromAPI: false,
        shouldUpdateToAPI: false,
        shouldLoadDependencies: false,
        /// 0: Load Project
        /// 1: Load Staff
        /// 2: Load Engine
        /// 3: Load Mechanic Group
        /// 4: Load Crew
        /// 5: Load Detail
        dependenciesLoadingStage: 0,
    });

    const [listAssignedCrew, setListAssignedCrew] = useState({
        data: [],
        isFinishedLoading: false,
    });

    const [listInspectionDetail, setListInspectionDetail] = useState([]);
    const [listInspectionDetailFromAPI, setListInspectionDetailFromAPI] = useState([]);

    const [listEngine, setListEngine] = useState({
        data: [],
        isFinishedLoading: false
    });
    const [shouldLoadEngineFromAPI, setShouldLoadEngineFromAPI] = useState(false);
    const [isEngineLoading, setIsEngineLoading] = useState(false);
    const [selectedEngine, setSelectedEngine] = useState(null);
    const [isSelectEngineModalVisible, setIsSelectEngineModalVisible] = useState(false);
    const [listMechanicGroup, setListMechanicGroup] = useState({
        data: [],
        isFinishedLoading: false
    });
    const [idSelectedOnListStaff, setIdSelectedOnListStaff] = useState('');
    const [searchListMechanicParameters, setSearchListMechanicParameters] = useState({
        groupName: ""
    });
    const [selectedMechanicGroup, setSelectedMechanicGroup] = useState(null);
    const [listCrew, setListCrew] = useState({
        data: [],
        isFinishedLoading: false
    });
    const [isSelectMechanicGroupModalVisible, setIsSelectMechanicGroupModalVisible] = useState(false);
    const [isSelectCrewModalVisible, setIsSelectCrewModalVisible] = useState(false);


    const [loading, setLoading] = useState(true);

    const [orderBy, setOrderBy] = useState({
        label: "Waktu Keberangkatan",
        value: "crewHistory.departure_date"
    });

    const orderByList = [
        {
            label: "Waktu Keberangkatan",
            value: "crewHistory.departure_date"
        }
    ]

    const options = {
        wordwrap: 130
    }

    const styles = {
        valueContainer: (base) => ({
            ...base,
            maxHeight: 30,
            overflowY: "auto"
        }),
    }

    // const generateDocument = async () => {
    //     try {
    //         const doc = new Document({
    //             sections: [
    //                 {
    //                     properties: {},
    //                     children: [
    //                         new Paragraph({
    //                             children: [
    //                                 new TextRun("Hello World"),
    //                                 new TextRun({
    //                                     text: "Foo Bar",
    //                                     bold: true,
    //                                     size: 40,
    //                                 }),
    //                                 new TextRun({
    //                                     children: [new Tab(), "Github is the best"],
    //                                     bold: true,
    //                                 }),
    //                             ],
    //                         }),
    //                     ],
    //                 },
    //             ],
    //         });

    //         const buffer = await Packer.toBuffer(doc);
    //         const blob = new Blob([buffer], { type: "text/plain;charset=utf-8" });
    //         saveAs(blob, "My Document.docx");
    //     }
    //     catch (exception) {
    //         console.log(exception);
    //     }

    // }




    useEffect(() => {
        async function checkCookies() {
            let isAuthenticated = await isTokenValid(cookies.token);
            if (!isAuthenticated)
                navigate("/");
            else {
                loadAccess();
            }
        }
        checkCookies();
    }, []);

    useEffect(() => {
        if (userAccess.isLoadAccessFinished) {
            initializePage();
        }
    }, [userAccess]);

    // useEffect(() => {
    //     console.log('addingNewInspectionDetailButton', addingNewInspectionDetailButton);
    //     if (addingNewInspectionDetailButton === true){
    //         addNewInspectionDetail();
    //     }
    // }, [addingNewInspectionDetailButton])

    // useEffect(() => {
    //    console.log("Parent", listInspectionDetail);
    //    const engineHealthInspectionDetails = engineHealthInspectionDetail.details;
    //    let combinedArr = [];
    //    if (listInspectionDetail.length > 0){
    //     combinedArr.push(listInspectionDetail, engineHealthInspectionDetails);
    //    } else{
    //     combinedArr.push(engineHealthInspectionDetails);
    //    }
    //    setEngineHealthInspectionDetail({...engineHealthInspectionDetail, details: combinedArr});
    // }, [listInspectionDetail]);

    useEffect(() => {
        console.log("Parent", listInspectionDetail);
        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, details: listInspectionDetail });
    }, [listInspectionDetail]);

    useEffect(() => {
        console.log('inputTestResultValue', inputTestResultValue);
    }, [inputTestResultValue])

    // useEffect(() => {
    //     console.log('listInspectionDetailFromAPI', listInspectionDetailFromAPI);
    //     setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, details: listInspectionDetailFromAPI });
    // }, [listInspectionDetailFromAPI])

    useEffect(() => {
        console.log(engineHealthInspectionId)
        if (engineHealthInspectionId === "") {
            setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, id: engineHealthInspectionId, isFinishedLoading: true, shouldLoadFromAPI: false });
        } else {
            setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, id: engineHealthInspectionId, shouldLoadFromAPI: true });
        }

    }, [engineHealthInspectionId]);

    useEffect(() => {
        if (engineHealthInspectionDetail.shouldLoadFromAPI) {
            loadEngineHealthInspectionDetail();
        }
        if (engineHealthInspectionDetail.shouldUpdateToAPI) {
            insertUpdateInspection();
        }

        if (engineHealthInspectionDetail.shouldLoadDependencies) {
            if (engineHealthInspectionDetail.dependenciesLoadingStage === 0) {
                loadSelectedProject();
            } else if (engineHealthInspectionDetail.dependenciesLoadingStage === 1) {
                if (engineHealthInspectionDetail.staff_id) {
                    loadSelectedStaff();
                } else {
                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, dependenciesLoadingStage: 2 });
                }
            } else if (engineHealthInspectionDetail.dependenciesLoadingStage === 2) {
                console.log('LOADING STAGE 2')
                if (engineHealthInspectionDetail.vessel_equipment_id) {
                    console.log('LOADIG STAGE 2', engineHealthInspectionDetail.vessel_equipment_id)
                    loadTestResultToForm();
                    loadSelectedEngine();
                } else {
                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, dependenciesLoadingStage: 3 });
                }
            } else if (engineHealthInspectionDetail.dependenciesLoadingStage === 3) {
                if (engineHealthInspectionDetail.mechanic_group_id) {
                    loadMechanicGroupList();
                } else {
                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, dependenciesLoadingStage: 4 });
                }
            } else if (engineHealthInspectionDetail.dependenciesLoadingStage === 4) {
                combineInspectionDetailArray();
                setEngineHealthInspectionDetail({
                    ...engineHealthInspectionDetail,
                    shouldLoadDependencies: false,
                    isFinishedLoading: true,
                    dependenciesLoadingStage: 0
                });
            }
        }

        if (engineHealthInspectionDetail.isFinishedLoading && !engineHealthInspectionDetail.shouldLoadDependencies &&
            engineHealthInspectionDetail.dependenciesLoadingStage === 0) {
            setLoading(false);
        }
        if (engineHealthInspectionDetail.project_id === "") {
            setIsSaveButtonDisabled(true);
        } else {
            setIsSaveButtonDisabled(false);
        }

        console.log("Detail Changed", engineHealthInspectionDetail);
    }, [engineHealthInspectionDetail]);

    useEffect(() => {
        if (shouldLoadProjectFromAPI) {
            if (!searchProjectParameters.isProjectNameChecked && !searchProjectParameters.isVesselNameChecked
                && !searchProjectParameters.isCustomerNameChecked && !searchProjectParameters.isProjectNumberChecked
            ) {
                alert("Mohon masukkan parameter pencarian proyek.");
                setShouldLoadProjectFromAPI(false);
                setIsProjectLoading(false);
            } else {
                loadProjectList();
            }

        }

        if (!searchProjectParameters.isProjectNameChecked) {
            setSearchProjectParameters({ ...searchProjectParameters, projectName: "" });
        }
        if (!searchProjectParameters.isVesselNameChecked) {
            setSearchProjectParameters({ ...searchProjectParameters, vesselName: "" });
        }
        if (!searchProjectParameters.isCustomerNameChecked) {
            setSearchProjectParameters({ ...searchProjectParameters, customerName: "" });
        }
        if (!searchProjectParameters.isProjectNumberChecked) {
            setSearchProjectParameters({ ...searchProjectParameters, projectNumber: "" });
        }

    }, [shouldLoadProjectFromAPI]);

    useEffect(() => {
        if (listProject.isFinishedLoading) {
            setIsProjectLoading(false);
            setShouldLoadProjectFromAPI(false);
        }
    }, [listProject]);

    useEffect(() => {
        if (selectedProject) {
            console.log("SELECT PROJECT", selectedProject);
            if (engineHealthInspectionDetail.shouldLoadDependencies) {
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, project_id: selectedProject.id, dependenciesLoadingStage: 1 });
            } else {
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, project_id: selectedProject.id, });
            }
            setSelectedEngine(null);
            setListEngine({
                data: [],
                isFinishedLoading: false
            });

        }
    }, [selectedProject]);

    useEffect(() => {
        if (shouldLoadStaffFromAPI) {
            if (!searchStaffParameters.isStaffNameChecked && !searchStaffParameters.isEmployeeNumberChecked
                && !searchStaffParameters.isStaffDivisionChecked
            ) {
                alert("Mohon masukkan parameter pencarian karyawan.");
                shouldLoadStaffFromAPI(false);
                setIsStaffLoading(false);
            } else {
                loadStaffList();
            }
        }

        if (!searchStaffParameters.isStaffNameChecked) {
            setSearchStaffParameters({ ...searchStaffParameters, staffName: "" });
        }
        if (!searchStaffParameters.isEmployeeNumberChecked) {
            setSearchStaffParameters({ ...searchStaffParameters, employeeNumber: "" });
        }
        if (!searchStaffParameters.isStaffDivisionChecked) {
            setSearchStaffParameters({ ...searchStaffParameters, staffDivision: "" });
        }

    }, [shouldLoadStaffFromAPI]);

    useEffect(() => {
        if (shouldLoadStaffForCrewFromAPI) {
            if (!searchStaffForNewCrew.isStaffNameChecked && !searchStaffForNewCrew.isEmployeeNumberChecked
                && !searchStaffForNewCrew.isStaffDivisionChecked
            ) {
                alert("Mohon masukkan parameter pencarian karyawan.");
                shouldLoadStaffForCrewFromAPI(false);
                setIsStaffForCrewLoading(false);
            } else {
                // loadStaffList();
                loadStaffListForCrew();
            }
        }

        if (!searchStaffForNewCrew.isStaffNameChecked) {
            setSearchStaffForNewCrew({ ...searchStaffForNewCrew, staffName: "" });
        }
        if (!searchStaffForNewCrew.isEmployeeNumberChecked) {
            setSearchStaffForNewCrew({ ...searchStaffForNewCrew, employeeNumber: "" });
        }
        if (!searchStaffForNewCrew.isStaffDivisionChecked) {
            setSearchStaffForNewCrew({ ...searchStaffForNewCrew, staffDivision: "" });
        }

    }, [shouldLoadStaffForCrewFromAPI]);

    useEffect(() => {
        if (listStaff.isFinishedLoading) {
            setIsStaffLoading(false);
            setShouldLoadStaffFromAPI(false);
        }
    }, [listStaff]);

    useEffect(() => {
        if (listStaffForNewCrew.isFinishedLoading) {
            console.log('listStaffForNewCrew', listStaffForNewCrew);
            setIsStaffForCrewLoading(false);
            setShouldLoadStaffForCrewFromAPI(false);
        }
    }, [listStaffForNewCrew]);

    useEffect(() => {
        if (selectedStaff) {
            console.log(selectedStaff);
            if (engineHealthInspectionDetail.shouldLoadDependencies) {
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, staff_id: selectedStaff.id, dependenciesLoadingStage: 2 });
            } else {
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, staff_id: selectedStaff.id });
            }
        } else {
            if (engineHealthInspectionDetail.shouldLoadDependencies) {
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, staff_id: null, dependenciesLoadingStage: 2 });
            }
        }
    }, [selectedStaff]);

    useEffect(() => {
        console.log('listSelectedStaffForNewCrew', listSelectedStaffForNewCrew);
    }, [listSelectedStaffForNewCrew])

    useEffect(() => {
        if (selectedStaffForNewCrew) {
            console.log(selectedStaffForNewCrew);
            selectedStaffForNewCrew.is_selected = true;
            selectedStaffForNewCrew.is_leader = false;
            let newCrew = engineHealthInspectionDetail.crew;
            newCrew.push(selectedStaffForNewCrew)
            setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: newCrew })
            // setListSelectedStaffForNewCrew(prev => [...prev, selectedStaffForNewCrew]);
            // let newCrew = [];
            // if (isAddNewCrewModalVisible){
            //     newCrew.push(selectedStaffForNewCrew);
            // }

        }

    }, [selectedStaffForNewCrew])

    useEffect(() => {
        if (listEngine.isFinishedLoading) {
            setShouldLoadEngineFromAPI(false);
            setIsSelectEngineModalVisible(true);
        }
    }, [listEngine]);

    useEffect(() => {
        if (selectedEngine) {
            setIsSelectEngineModalVisible(false);
            if (engineHealthInspectionDetail.shouldLoadDependencies) {
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, vessel_equipment_id: selectedEngine.vessel_equipment_id, dependenciesLoadingStage: 3 });
            } else {
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, vessel_equipment_id: selectedEngine.vessel_equipment_id });
            }
        } else {
            if (engineHealthInspectionDetail.shouldLoadDependencies) {
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, vessel_equipment_id: null, dependenciesLoadingStage: 3 });
            }
        }
    }, [selectedEngine]);

    useEffect(() => {
        if (listMechanicGroup.isFinishedLoading) {
            if (engineHealthInspectionDetail.mechanic_group_id) {
                let selectedGroup = listMechanicGroup.data.find(p => p.id === engineHealthInspectionDetail.mechanic_group_id);
                setSelectedMechanicGroup(selectedGroup);
            }
        }
    }, [listMechanicGroup]);

    useEffect(() => {
        if (selectedMechanicGroup) {
            console.log("SELECTED MECHANIC GROUP", selectedMechanicGroup);
            if (engineHealthInspectionDetail.dependenciesLoadingStage !== 3) {
                if (engineHealthInspectionDetail.id === "") {
                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, mechanic_group_id: selectedMechanicGroup.id, crew: selectedMechanicGroup.list_staff });
                }
                //setListCrew({ ...listCrew, data: selectedMechanicGroup.list_staff });
            } else {
                console.log("SELECTED MECHANIC GROUP", selectedMechanicGroup);
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, dependenciesLoadingStage: 4 });
            }
        }
    }, [selectedMechanicGroup]);

    // useEffect(() => {
    //     if (selectedMechanicGroup){
    //         if (idSelectedOnListStaff){
    //             let findStaff = selectedMechanicGroup.list_staff.find(p => p.id === idSelectedOnListStaff);
    //             findStaff.is_selected = true;
    //             setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: selectedMechanicGroup});
    //             // console.log(findStaff);
    //             // selectedMechanicGroup.list_staff.push(findStaff);
    //             // console.log(selectedMechanicGroup);
    //         } else {

    //         }
    //     }
    // }, [idSelectedOnListStaff])

    useEffect(() => {
        if (listCrew) {
            console.log('list crew', listCrew);
            if (engineHealthInspectionDetail.isFinishedLoading) {
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: listCrew.data });
            }
        }
    }, [listCrew]);

    useEffect(() => {
        if (shouldLoadEngineFromAPI) {
            loadEngineList();
        }

    }, [shouldLoadEngineFromAPI]);

    const combineInspectionDetailArray = () => {
        if (engineHealthInspectionDetail.details) {
            let inspectionDetailsArr = structuredClone(engineHealthInspectionDetail.details);
            console.log('inspectionDetailsArr', inspectionDetailsArr);
            setListInspectionDetail(inspectionDetailsArr);
        }
    }

    const loadTestResultToForm  = () => {
        engineHealthInspectionDetail.test_result.test_result_data.map((data, index) => {
            setInputTestResultValue((prev) => ({...prev, [`${data.engine_test_load_id}_${data.engine_test_parameter_id}`]: data.engine_test_result_value }));
            // console.log('loadid', data.engine_test_load_id);
            // console.log('paramid', data.engine_test_parameter_id);
            // console.log('value', data.engine_test_result_value);
            // console.log('-------------------------------------------')
        })
        
    }

    const changeSelectedCrew = async (crewId, status) => {
        if (selectedMechanicGroup) {
            if (status === true) {
                let findStaff = selectedMechanicGroup.list_staff.find(p => p.id === crewId);
                findStaff.is_selected = true;
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: selectedMechanicGroup });
                // console.log(findStaff);
                // selectedMechanicGroup.list_staff.push(findStaff);
                // console.log(selectedMechanicGroup);
            } else {
                let findStaff = selectedMechanicGroup.list_staff.find(p => p.id === crewId);
                findStaff.is_selected = false;
                setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: selectedMechanicGroup });
            }
        }
    }

    const loadAccess = async () => {
        try {
            let response = await getUserAccessById(cookies.token, window.location.pathname.substring(0, window.location.pathname.lastIndexOf("/")), cookies.languageId);
            if (response) {
                setUserAccess({ allowCreate: response.updated, allowDelete: response.deleted, isLoadAccessFinished: true });
            }
        } catch (exception) {
            console.log(exception);
        }
    }

    //iniitalize page
    const initializePage = async () => {
        try {
            loadMechanicGroupList();
            setEngineHealthInspectionId(location.state.engineHealthInspectionId);
            // console.log('location.state.engineHealthInspectionId', location.state.engineHealthInspectionId);
            // if (engineHealthInspectionDetail.id !== "") {
            //     loadMechanicGroupList();
            //     setEngineHealthInspectionId(location.state.engineHealthInspectionId);
            // } else {
            //     setEngineHealthInspectionId(location.state.engineHealthInspectionId);
            // }
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadEngineHealthInspectionDetail = async () => {
        try {
            let resp = await findEngineHealthInspectionById(cookies.token, engineHealthInspectionId);
            setEngineHealthInspectionDetail({
                ...engineHealthInspectionDetail, ...resp, isFinishedLoading: false, shouldUpdateToAPI: false,
                shouldLoadFromAPI: false, shouldLoadDependencies: true
            });
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadSelectedProject = async () => {
        try {
            let resp = await findProjectForEngineHealthInspectionById(cookies.token, engineHealthInspectionDetail.project_id);
            console.log(resp);
            setSelectedProject(resp);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadSelectedStaff = async () => {
        try {
            let resp = await findStaffForEngineHealthInspectionById(cookies.token, cookies.languageId, engineHealthInspectionDetail.staff_id);
            console.log(resp);
            setSelectedStaff(resp);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadSelectedEngine = async () => {
        try {
            let resp = await findEngineForEngineHealthInspectionById(cookies.token, engineHealthInspectionDetail.vessel_equipment_id);
            console.log(resp);
            setSelectedEngine(resp);
        }
        catch (exception) {
            console.log(exception);
        }
    }

    const insertUpdateInspection = async () => {
        try {
            let resp = await insertUpdateEngineHealthInspectionDetail(cookies.token, engineHealthInspectionDetail);

            console.log("INSERT UPDATE", resp);
            setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, ...resp, isFinishedLoading: true, shouldUpdateToAPI: false, shouldLoadFromAPI: false });
            setListInspectionDetail(structuredClone(resp.data.details));
            if (resp.error_code === 0) {
                alert("Perubahan berhasil di simpan");
            } else {
                alert("Gagal menyimpan perubahan");
            }

        }
        catch (exception) {
            console.log(exception);
        }
    }

    const loadProjectList = async () => {
        try {
            let resp = await findProjectForEngineHealthInspection(cookies.token, cookies.languageId, cookies.extCustomerId, searchProjectParameters);
            setListProject({ ...listProject, data: resp, isFinishedLoading: true });
        }
        catch (exception) {
            setListProject({ ...listProject, data: [], isFinishedLoading: true });
            console.log(exception);
        }
    }

    const loadStaffList = async () => {
        try {
            let resp = await findStaffForEngineHealthInspection(cookies.token, cookies.languageId, searchStaffParameters);
            setListStaff({ ...listStaff, data: resp, isFinishedLoading: true });
        }
        catch (exception) {
            setListStaff({ ...listStaff, data: [], isFinishedLoading: true });
            console.log(exception);
        }
    }

    const loadStaffListForCrew = async () => {
        try {
            let resp = await findStaffByFieldStaff(cookies.token, cookies.languageId, searchStaffForNewCrew);
            setListStaffForNewCrew({ ...listStaffForNewCrew, data: resp, isFinishedLoading: true });
        }
        catch (exception) {
            setListStaffForNewCrew({ ...listStaffForNewCrew, data: [], isFinishedLoading: true });
            console.log(exception);
        }
    }

    const loadEngineList = async () => {
        try {
            let resp = await findEngineByVesselId(cookies.token, selectedProject.vessel_id);
            setListEngine({ ...listEngine, data: resp, isFinishedLoading: true });
        }
        catch (exception) {
            setListEngine({ ...listEngine, data: [], isFinishedLoading: true });
            console.log(exception);
        }
    }

    const loadMechanicGroupList = async () => {
        try {
            let resp = await findAllMechanicGroupForEngineHealthInspection(cookies.token, searchListMechanicParameters);
            console.log(resp);
            setListMechanicGroup({ ...listMechanicGroup, data: resp, isFinishedLoading: true });
        }
        catch (exception) {
            setListMechanicGroup({ ...listMechanicGroup, data: [], isFinishedLoading: true });
            console.log(exception);
        }
    }
    const loadListInspectionDetail = async () => {
        try {
            let resp = await findAllInspectionDetail(cookies.token, engineHealthInspectionDetail.engine_health_inspection_id);
            console.log('loadListInspectionDetail', resp);
            setListInspectionDetailFromAPI(resp);
            // setListMechanicGroup({ ...listMechanicGroup, data: resp, isFinishedLoading: true });
        }
        catch (exception) {
            // setListMechanicGroup({ ...listMechanicGroup, data: [], isFinishedLoading: true });
            console.log(exception);
        }
    }

    const onSearchProjectSubmit = () => {
        setIsProjectLoading(true);
        setShouldLoadProjectFromAPI(true);
    };

    const onSearchStaffSubmit = () => {
        setIsStaffLoading(true);
        setShouldLoadStaffFromAPI(true);
    };

    const onSearchStaffForCrewSubmit = () => {
        setIsStaffForCrewLoading(true);
        setShouldLoadStaffForCrewFromAPI(true);
    };

    const removeSelectedCrew = (mechanicId) => {
        let crew = engineHealthInspectionDetail.crew;
        let mecIdx = crew.findIndex(crew => crew.staff_id === mechanicId);
        if (mecIdx !== -1) {
            crew.splice(mecIdx, 1);
        }
        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
    }

    const addNewInspectionDetail = () => {
        let listTmpDetail = structuredClone(listInspectionDetail);
        let inspectionDetailObj = {
            id: "",
            engine_health_inspection_id: engineHealthInspectionDetail.engine_health_inspection_id,
            vessel_equipment_id: '',
            start_date: moment(new Date()).local().format("YYYY-MM-DD"),
            end_date: moment(new Date()).local().format("YYYY-MM-DD"),
            inspection_result: '',
            recommendation: '',
            created_date: '',
            updated_date: '',
            created_by: '',
            updated_by: '',
            is_active: true,
            inspection_task: '',
            vessel_equipment_name: '',
            photo: [],
            doc: []
        }
        listTmpDetail.push(inspectionDetailObj);

        setListInspectionDetail(listTmpDetail);
    }

    const updateListInspectionDetail = (index, detail) => {

        let listDetail = structuredClone(listInspectionDetail);
        listDetail[index] = detail;
        console.log(detail);
        setListInspectionDetail(listDetail);

        
    };

    const removeInspectionDetail = (tmpDetail) => {
        let inspectionDetail = engineHealthInspectionDetail.details;
        let mecIdx = inspectionDetail.findIndex(inspectionDetail => inspectionDetail.tmpDetail === "");
        if (mecIdx !== -1) {
            inspectionDetail.splice(mecIdx, 1);
        }
        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, details: inspectionDetail });
    }

    const addNewTestResult = (loadId, paramId, value) => {
        if (isTestResultValueAdded) {
            return;
        }

        let testResultArr = engineHealthInspectionDetail.test_result;
        let testResultDataArr = engineHealthInspectionDetail.test_result.test_result_data;

        if (value === '') {
            let updatedTestResultDataArr = testResultDataArr.filter(p => p.engine_test_load_id !== loadId || p.engine_test_parameter_id !== paramId);
            testResultArr.test_result_data = updatedTestResultDataArr;
            setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, test_result: testResultArr });
            return;
        }

        let updatedTestResultDataArr = testResultDataArr.map(p => {
            if (p.engine_test_load_id === loadId && p.engine_test_parameter_id === paramId) {
                return {...p, engine_test_result_value: value};
            }
            return p;
        });

        if (!updatedTestResultDataArr.some(p => p.engine_test_load_id === loadId && p.engine_test_parameter_id === paramId)) {
            updatedTestResultDataArr.push({
                engine_health_inspection_id: engineHealthInspectionDetail.engine_health_inspection_id,
                engine_test_parameter_id: paramId,
                engine_test_load_id: loadId,
                engine_test_result_value: value,
            });
        }

        testResultArr.test_result_data = updatedTestResultDataArr;
        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, test_result: testResultArr });
        setIsTestResultValueAdded(true);
    };
    

    const handleTestResultInputChange = (loadId, paramId, e) => {
        setInputTestResultValue((prev) => ({...prev, [`${loadId}_${paramId}`]: e.target.value }));
        setIsTestResultValueAdded(false);
    }

    // const downloadDocxFile = async () => {
    //     const element = document.createElement("a");
    //     const file = new Blob([docxData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    //     element.href = URL.createObjectURL(file);
    //     element.download = `${reportProjectName}-${moment(troubleshooting.report_date).format("yyyy-MM-DD")}.docx`;
    //     document.body.appendChild(element); // Required for this to work in FireFox
    //     element.click();

    //     // console.log(file);
    //     // console.log(file.length);

    //     URL.revokeObjectURL(file);
    //     setLoading(false);

    //     setDisabledPrint(false);
    // }

    const downloadDocxFile = async (doc) => {
        const element = document.createElement("a");
        const file = new Blob([doc], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        element.href = URL.createObjectURL(file);
        element.download = `${engineHealthInspectionDetail.project_name}-${moment(engineHealthInspectionDetail.start_date).format("yyyy-MM-DD")}.docx`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        // console.log(file);
        // console.log(file.length);

        URL.revokeObjectURL(file);
        setLoading(false);

        //setDisabledPrint(false);
    }

    const generateDocument = async () => {
        try {
            console.log('click')
            let docx = await getDocumentEngineHealthInspection(cookies.token, engineHealthInspectionDetail.engine_health_inspection_id, engineHealthInspectionDetail.mechanic_group_id);
            console.log(docx);
            if (docx.size) {
                downloadDocxFile(docx);
            } else {
                alert(`Gagal Membuat Report, Mohon Hubungi Support.`);
                setLoading(false);
                //setDisabledPrint(false);
            }

        } catch (exception) {
            console.log(exception)
        }
    }



    return (
        <>
            <Navbar />
            <Container fluid style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
            }}>
                <ContainerBox titleCaption={"Detail Inspeksi Mesin"}
                    useBackButton={true}
                    backButtonChild={
                        <div>
                            <Button variant="secondary"
                                onClick={() => { navigate("/EngineHealthInspection"); }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><ArrowLeft size={32} /></div>
                                </div>
                            </Button></div>
                    }
                    useActionContainer={engineHealthInspectionId === "" ? false : true}
                    actionContainerChild={
                        <div>
                            <Link exact to={`/EngineHealthInspection/Photo?engineHealthInspectionId=${engineHealthInspectionId}&projectName=${engineHealthInspectionDetail.project_name}`} activeClassName="activeClicked" target="_blank" style={{ textDecoration: 'none' }}>
                                <Button disabled={false} variant="secondary" style={{

                                }} onClick={() => {
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><Image size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>Foto</div>
                                    </div>
                                </Button>
                            </Link>

                            <Link exact to={`/EngineHealthInspection/Document?engineHealthInspectionId=${engineHealthInspectionId}&projectName=${engineHealthInspectionDetail.project_name}`} activeClassName="activeClicked" target="_blank" style={{ textDecoration: 'none' }}>
                                <Button disabled={false} variant="secondary" style={{
                                    marginLeft: '10px'
                                }} onClick={() => {
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        alignSelf: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            flex: 1,
                                        }}><Newspaper size={32} /></div>
                                        <div style={{
                                            display: "flex",
                                            flex: 8,
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            alignSelf: "center",
                                            paddingLeft: 10,
                                            fontWeight: "bold",
                                            fontSize: 18,
                                        }}>Dokumen</div>
                                    </div>
                                </Button>
                            </Link>

                            <Button disabled={true} variant="secondary" style={{
                                marginLeft: '10px'
                            }} onClick={() => {
                                generateDocument();
                            }}>
                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    alignSelf: "center",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        flex: 1,
                                    }}><PrinterFill size={32} /></div>
                                    <div style={{
                                        display: "flex",
                                        flex: 8,
                                        alignContent: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        alignSelf: "center",
                                        paddingLeft: 10,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                    }}>Print Report</div>
                                </div>
                            </Button>
                        </div>
                    }
                    childContent={
                        <div>
                            <div style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                padding: 5,
                                width: "100%",
                                flexWrap: "nowrap",
                            }}>



                                <div style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    width: "100%",
                                    flexWrap: "nowrap",
                                    paddingRight: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "column",
                                        paddingBottom: 5,
                                        width: "100%",
                                        flexWrap: "nowrap",
                                    }}>

                                        <ContainerBox containerPos="inner" titleCaption={"Informasi Umum"} childContent={
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: 15
                                            }}>
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    setLoading(true);
                                                    setTimeout(function () {
                                                        window.scrollTo(0, 0);
                                                    }, 2);
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        width: "100%",
                                                        justifyContent: "space-around",
                                                        flex: 1,
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 3,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                }}>
                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Nama Proyek</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={true} required type="text" onChange={(e) => {

                                                                    }} value={selectedProject ? (`${selectedProject.project_number} : ${selectedProject.project_name}`) : ""}></Form.Control>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                    alignContent: "flex-start",
                                                                    alignItems: "flex-start",
                                                                    justifyContent: "flex-start",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}></div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                        paddingLeft: 20,
                                                                        paddingRight: 20
                                                                    }}>
                                                                        <Button style={{
                                                                            width: "100%"
                                                                        }} variant="primary" onClick={() => {
                                                                            setIsSelectProjectModalVisible(true);
                                                                        }}>Cari</Button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                }}>
                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Tanggal Mulai Inspeksi</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="datetime-local" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, start_date: moment(e.target.value).format("yyyy-MM-DD HH:mm") });
                                                                    }} value={moment(engineHealthInspectionDetail.start_date).isValid() ? moment(engineHealthInspectionDetail.start_date).format("yyyy-MM-DD HH:mm") : moment(new Date()).format("yyyy-MM-DD HH:mm")}></Form.Control>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 3,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                }}>
                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Penanggung Jawab Proyek</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={true} required type="text" onChange={(e) => {

                                                                    }} value={selectedStaff ? (`${selectedStaff.staff_name} (${selectedStaff.employee_number})`) : ""}></Form.Control>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                    alignContent: "flex-start",
                                                                    alignItems: "flex-start",
                                                                    justifyContent: "flex-start",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}></div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                        paddingLeft: 20,
                                                                        paddingRight: 20
                                                                    }}>
                                                                        <Button style={{
                                                                            width: "100%"
                                                                        }} variant="primary" onClick={() => {
                                                                            setIsSelectStaffModalVisible(true);
                                                                        }}>Cari</Button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                }}>
                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Tanggal Selesai Inspeksi</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="datetime-local" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, end_date: moment(e.target.value).format("yyyy-MM-DD HH:mm") });
                                                                    }} value={moment(engineHealthInspectionDetail.end_date).isValid() ? moment(engineHealthInspectionDetail.end_date).format("yyyy-MM-DD HH:mm") : moment(new Date()).format("yyyy-MM-DD HH:mm")}></Form.Control>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 3,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                }}>
                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Pilih Mesin</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={true} required type="text" onChange={(e) => {

                                                                    }} value={selectedEngine ? (`${selectedEngine.title} (${selectedEngine.serial_number})`) : ""}></Form.Control>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                    alignContent: "flex-start",
                                                                    alignItems: "flex-start",
                                                                    justifyContent: "flex-start",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}></div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                        paddingLeft: 20,
                                                                        paddingRight: 20
                                                                    }}>
                                                                        <Button style={{
                                                                            width: "100%"
                                                                        }} variant="primary" disabled={selectedProject ? false : true}
                                                                            onClick={() => {
                                                                                setLoading(true);
                                                                                setShouldLoadEngineFromAPI(true);
                                                                            }}>Pilih</Button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                }}>
                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Lokasi Inspeksi</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, inspection_location: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.inspection_location ? engineHealthInspectionDetail.inspection_location : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 28,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>PIC Customer 1</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, customer_pic: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.customer_pic ? engineHealthInspectionDetail.customer_pic : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}>

                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Jabatan PIC Customer 1</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, customer_pic_position: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.customer_pic_position ? engineHealthInspectionDetail.customer_pic_position : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                }}>

                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Nomor Telepon PIC Customer 1</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, customer_pic_phone_number: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.customer_pic_phone_number ? engineHealthInspectionDetail.customer_pic_phone_number : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 28,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>PIC Customer 2</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, customer_pic_2: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.customer_pic_2 ? engineHealthInspectionDetail.customer_pic_2 : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}>

                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Jabatan PIC Customer 2</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, customer_pic_2_position: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.customer_pic_2_position ? engineHealthInspectionDetail.customer_pic_2_position : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                }}>

                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Nomor Telepon PIC Customer 2</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, customer_pic_2_phone_number: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.customer_pic_2_phone_number ? engineHealthInspectionDetail.customer_pic_2_phone_number : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 28,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}>
                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>PIC Customer 3</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, customer_pic_3: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.customer_pic_3 ? engineHealthInspectionDetail.customer_pic_3 : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                    paddingRight: 5,
                                                                }}>

                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Jabatan PIC Customer 3</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, customer_pic_3_position: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.customer_pic_3_position ? engineHealthInspectionDetail.customer_pic_3_position : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                width: "100%",
                                                                justifyContent: "space-around",
                                                                flex: 1,
                                                            }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 8,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    paddingTop: 5,
                                                                    paddingBottom: 5,
                                                                    paddingLeft: 5,
                                                                }}>

                                                                    <Form.Label style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }}>Nomor Telepon PIC Customer 3</Form.Label>
                                                                    <Form.Control style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        width: "100%",
                                                                        flex: 1,
                                                                    }} disabled={false} required type="text" onChange={(e) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, customer_pic_3_phone_number: e.target.value });
                                                                    }} value={engineHealthInspectionDetail.customer_pic_3_phone_number ? engineHealthInspectionDetail.customer_pic_3_phone_number : ""}></Form.Control>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            width: "100%",
                                                            justifyContent: "flex-start",
                                                            flex: 1,
                                                        }}>
                                                            <Form.Group className="formGroupFormPlanningContanerNew">
                                                                <Form.Label>Deskripsi</Form.Label>
                                                                <Editor
                                                                    apiKey='rxnc1oy2exywbxkggp1tifq3iax959rxiwme9crki1rxr9t1'
                                                                    disabled={false}
                                                                    onEditorChange={(evt, editor) => {
                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, engine_health_inspection_description: evt });
                                                                    }}
                                                                    value={engineHealthInspectionDetail.engine_health_inspection_description}
                                                                    init={{
                                                                        height: 250,
                                                                        menubar: false,
                                                                        plugins: [
                                                                            'advlist', 'autolink',
                                                                            'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                                            'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'code'
                                                                        ],
                                                                        toolbar1: 'undo redo |  bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | outdent indent ',
                                                                        toolbar2: 'fontsize fontfamily |' + 'bullist numlist checklist  |   table  |  code fullscreen',
                                                                        image_title: true,
                                                                        automatic_uploads: true,
                                                                        /*
                                                                          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                                                                          images_upload_url: 'postAcceptor.php',
                                                                          here we add custom filepicker only to Image dialog
                                                                        */
                                                                        file_picker_types: 'image',
                                                                        /* and here's our custom image picker*/
                                                                        file_picker_callback: (cb, value, meta) => {
                                                                            const input = document.createElement('input');
                                                                            input.setAttribute('type', 'file');
                                                                            input.setAttribute('accept', 'image/*');

                                                                            input.addEventListener('change', (e) => {
                                                                                const file = e.target.files[0];
                                                                                const reader = new FileReader();
                                                                                reader.addEventListener('load', () => {
                                                                                    /*
                                                                                      Note: Now we need to register the blob in TinyMCEs image blob
                                                                                      registry. In the next release this part hopefully won't be
                                                                                      necessary, as we are looking to handle it internally.
                                                                                    */

                                                                                    // console.log(editorRef.current.getContent())
                                                                                    const id = 'blobid' + (new Date()).getTime();
                                                                                    const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                                                                                    //   editorRef.current.editorUpload.blobCache

                                                                                    //Editor.activeEditor.editorUpload.blobCache;

                                                                                    const base64 = reader.result.split(',')[1];
                                                                                    const blobInfo = blobCache.create(id, file, base64);
                                                                                    blobCache.add(blobInfo);

                                                                                    /* call the callback and populate the Title field with the file name */
                                                                                    cb(blobInfo.blobUri(), { title: file.name });
                                                                                });
                                                                                reader.readAsDataURL(file);
                                                                            });

                                                                            input.click();
                                                                        },
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        }>
                                        </ContainerBox>
                                        <div style={{
                                            paddingTop: 20
                                        }} ></div>
                                        <ContainerBox containerPos="inner" titleCaption={"Informasi Proyek dan Kapal "}
                                            childContent={
                                                <div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: 15
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: '100%',
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Nama Proyek</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={true} required type="text" onChange={(e) => {

                                                                }} value={selectedProject ? (`${selectedProject.project_number} : ${selectedProject.project_name}`) : ""}></Form.Control>
                                                            </div>

                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Perusahaan</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={true} required type="text" onChange={(e) => {

                                                                }} value={selectedProject ? (`${selectedProject.customer_name}`) : ""}></Form.Control>
                                                            </div>

                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: '100%',
                                                        }}>


                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Tipe Proyek</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={true} required type="text" onChange={(e) => {

                                                                }} value={selectedProject ? (`${selectedProject.project_type_name}`) : ""}></Form.Control>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Status Proyek</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={true} required type="text" onChange={(e) => {

                                                                }} value={selectedProject ? (`${selectedProject.project_status_name}`) : ""}></Form.Control>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Tanggal Mulai</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={true} required type="date" onChange={(e) => {

                                                                }} value={selectedProject ? (`${moment(selectedProject.estimated_start_date).local().format("YYYY-MM-DD")}`) : ""}></Form.Control>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Tanggal Selesai</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={true} required type="date" onChange={(e) => {

                                                                }} value={selectedProject ? (`${moment(selectedProject.estimated_end_date).local().format("YYYY-MM-DD")}`) : ""}></Form.Control>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: '100%',
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Nama Kapal</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={true} required type="text" onChange={(e) => {

                                                                }} value={selectedProject ? (`${selectedProject.vessel_name}`) : ""}></Form.Control>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 1,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Lokasi Kapal</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={true} required type="text" onChange={(e) => {

                                                                }} value={selectedProject ? (`${selectedProject.vessel_location}`) : ""}></Form.Control>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            }>
                                        </ContainerBox>

                                        <div style={{
                                            paddingTop: 20
                                        }} ></div>

                                        <ContainerBox containerPos="inner" titleCaption={"Hasil Test"}
                                            childContent={
                                                <div style={{ padding: '10px', width: '100%' }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                        justifyContent: "space-around",
                                                        flex: 1,
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 28,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                                paddingRight: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Engine Speed Range (min)</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={false} required type="text" onChange={(e) => {
                                                                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, rpm_range_min: e.target.value });
                                                                }} value={engineHealthInspectionDetail.rpm_range_min ? engineHealthInspectionDetail.rpm_range_min : ""}></Form.Control>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 28,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                                paddingRight: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Engine Speed Range (max)</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={false} required type="text" onChange={(e) => {
                                                                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, rpm_range_max: e.target.value });
                                                                }} value={engineHealthInspectionDetail.rpm_range_max ? engineHealthInspectionDetail.rpm_range_max : ""}></Form.Control>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                        justifyContent: "space-around",
                                                        flex: 1,
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 28,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                                paddingRight: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Engine Power</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={false} required type="text" onChange={(e) => {
                                                                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, engine_power: e.target.value });
                                                                }} value={engineHealthInspectionDetail.engine_power ? engineHealthInspectionDetail.engine_power : ""}></Form.Control>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            justifyContent: "space-around",
                                                            flex: 1,
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                width: "100%",
                                                                flex: 28,
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                paddingLeft: 5,
                                                                paddingRight: 5,
                                                            }}>
                                                                <Form.Label style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }}>Governor</Form.Label>
                                                                <Form.Control style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    width: "100%",
                                                                    flex: 1,
                                                                }} disabled={false} required type="text" onChange={(e) => {
                                                                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, governor_type: e.target.value });
                                                                }} value={engineHealthInspectionDetail.governor_type ? engineHealthInspectionDetail.governor_type : ""}></Form.Control>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: '3px' }}>
                                                        <div className="table-container">
                                                            <div className="table-header">
                                                                <div style={{ flex: 3, display: 'flex', justifyContent: 'center' }} className="table-header-content">Load</div>
                                                                {
                                                                    engineHealthInspectionDetail && engineHealthInspectionDetail.test_result && engineHealthInspectionDetail.test_result.test_result_load.map((load, index) => {
                                                                        return(<>
                                                                            <div key={index} style={{ flex: 1, display: 'flex', justifyContent: 'center' }} className="table-header-content">{load.load_percentage}%</div>
                                                                        </>)
                                                                    })
                                                                }
                                                            </div>
                                                            
                                                            {
                                                                engineHealthInspectionDetail && engineHealthInspectionDetail.test_result && engineHealthInspectionDetail.test_result.test_result_param.map((param, index) => {
                                                                    return (<div className="table-body">
                                                                        <div style={{ flex: 3, display: 'flex', justifyContent: 'center' }} className="table-body-content">{param.parameter_label}</div>
                                                                        {
                                                                            engineHealthInspectionDetail && engineHealthInspectionDetail.test_result && engineHealthInspectionDetail.test_result.test_result_load.map((load, index) => {
                                                                                return (<>
                                                                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} className="table-body-content">
                                                                                        <Form.Control style={{
                                                                                            display: "flex",
                                                                                            flexDirection: "column",
                                                                                            width: "100%",
                                                                                            flex: 1,
                                                                                        }} disabled={false} required type="text" 
                                                                                            onChange={(e) => {handleTestResultInputChange(load.id, param.id, e); console.log(e)}}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.key === 'Enter') {
                                                                                                    console.log(load.load_percentage, param.parameter_label);
                                                                                                    addNewTestResult(load.id, param.id, inputTestResultValue[`${load.id}_${param.id}`]);
                                                                                                }
                                                                                            }}
                                                                                            onBlur={() => {
                                                                                                addNewTestResult(load.id, param.id, inputTestResultValue[`${load.id}_${param.id}`]);
                                                                                            }}
                                                                                            value={inputTestResultValue[`${load.id}_${param.id}`]}></Form.Control>
                                                                                    </div>
                                                                                </>)
                                                                            })
                                                                        }
                                                                    </div>)
                                                                })
                                                            }
                                                            
                                                            {
                                                                // engineHealthInspectionDetail && engineHealthInspectionDetail.crew.filter(crew => crew.is_leader).map((mechanic, index) => {
                                                                //     return (

                                                                //         <div className="table-body">
                                                                //             <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} className="table-body-content"><p>{(index + 1)}</p></div>
                                                                //             <div style={{ flex: 3 }} className="table-body-content"><p>{mechanic.staff_name}</p></div>
                                                                //             <div style={{ flex: 2 }} className="table-body-content">
                                                                //                 <Form.Control style={{
                                                                //                     display: "flex",
                                                                //                     flexDirection: "column",
                                                                //                     width: "100%",
                                                                //                     flex: 1,
                                                                //                 }} disabled={!isEditDetail} required type="date" onChange={(e) => {
                                                                //                     let crew = engineHealthInspectionDetail.crew;
                                                                //                     crew.find(crew => crew.staff_id === mechanic.staff_id).arrived_at = moment(e.target.value).local().format("YYYY-MM-DD");
                                                                //                     setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                //                 }} value={mechanic.arrived_at ? moment(mechanic.arrived_at).local().format("YYYY-MM-DD") : ""}></Form.Control>
                                                                //             </div>
                                                                //             <div style={{ flex: 2 }} className="table-body-content">
                                                                //                 <Form.Control style={{
                                                                //                     display: "flex",
                                                                //                     flexDirection: "column",
                                                                //                     width: "100%",
                                                                //                     flex: 1,
                                                                //                 }} disabled={!isEditDetail} required type="date" onChange={(e) => {
                                                                //                     let crew = engineHealthInspectionDetail.crew;
                                                                //                     crew.find(crew => crew.staff_id === mechanic.staff_id).departure_date = moment(e.target.value).local().format("YYYY-MM-DD");
                                                                //                     setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                //                 }} value={mechanic.departure_date ? moment(mechanic.departure_date).local().format("YYYY-MM-DD") : ""}></Form.Control>
                                                                //             </div>
                                                                //             <div style={{ flex: 2 }} className="table-body-content">
                                                                //                 <Form.Control style={{
                                                                //                     display: "flex",
                                                                //                     flexDirection: "column",
                                                                //                     width: "100%",
                                                                //                     flex: 1,
                                                                //                 }} disabled={!isEditDetail} required type="text" onChange={(e) => {
                                                                //                     let crew = engineHealthInspectionDetail.crew;
                                                                //                     crew.find(crew => crew.staff_id === mechanic.staff_id).arrived_from = e.target.value;
                                                                //                     setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                //                 }} value={mechanic.arrived_from ? mechanic.arrived_from : ""}></Form.Control>
                                                                //             </div>
                                                                //             <div style={{ flex: 2 }} className="table-body-content"><p>{selectedMechanicGroup && selectedMechanicGroup.id === mechanic.mechanic_group_id ? selectedMechanicGroup.group_name : ""}</p></div>
                                                                //             <div style={{ flex: 1 }} className="table-body-content">
                                                                //                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                                //                     <Form.Check disabled={false} defaultChecked={mechanic.is_selected} onClick={(e) => {
                                                                //                         let crew = engineHealthInspectionDetail.crew;
                                                                //                         let listGroupStaff = selectedMechanicGroup.list_staff;
                                                                //                         if (e.target.checked) {
                                                                //                             // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = true;
                                                                //                             // crew.push(mechanic);
                                                                //                             // changeSelectedCrewOnListStaff(mechanic.id)
                                                                //                             //changeSelectedCrew(mechanic.id, true);
                                                                //                             // console.log(mechanic.is_selected);
                                                                //                             crew.find(p => p.staff_id === mechanic.staff_id).is_selected = true;
                                                                //                         } else {
                                                                //                             // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = false;
                                                                //                             // let mecIdx = crew.findIndex(p=>p.staff_id === mechanic.staff_id);
                                                                //                             // if (mecIdx !== -1){
                                                                //                             //     crew.splice(mecIdx, 1);
                                                                //                             // }

                                                                //                             crew.find(p => p.staff_id === mechanic.staff_id).is_selected = false;
                                                                //                             //changeSelectedCrew(mechanic.id, false);
                                                                //                         }
                                                                //                         // setSelectedMechanicGroup({...selectedMechanicGroup, list_staff: listGroupStaff});
                                                                //                         setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                //                     }}>
                                                                //                     </Form.Check>
                                                                //                 </div>
                                                                //             </div>
                                                                //             <div style={{ flex: 1 }} className="table-body-content">
                                                                //                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                                //                     <Button variant="danger" disabled={selectedMechanicGroup && selectedMechanicGroup.id === mechanic.mechanic_group_id ? true : false} onClick={() => {
                                                                //                         // removeSelectedCrew(mechanic.staff_id);
                                                                //                     }}><Trash />
                                                                //                     </Button>
                                                                //                 </div>
                                                                //             </div>
                                                                //         </div>

                                                                //     )
                                                                // })


                                                            }
                                                        </div>
                                                    </div>

                                                </div>}>

                                        </ContainerBox>

                                        <div style={{
                                            paddingTop: 20
                                        }} ></div>
                                        {/* {
                                            engineHealthInspectionDetail.id !== "" ? (<> */}
                                        <ContainerBox containerPos="inner" titleCaption={"Data Teknisi"}
                                            childContent={
                                                <div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: 15
                                                    }}>
                                                        <div>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Pilih Group Teknisi</Form.Label>
                                                                <Form.Select required disabled={engineHealthInspectionDetail.id !== "" ? true : false} onChange={(e) => {
                                                                    if (e.target.value === null) {
                                                                        setSelectedMechanicGroup(null);
                                                                    } else {
                                                                        setSelectedMechanicGroup(listMechanicGroup.data.find(p => p.id === e.target.value));
                                                                    }

                                                                }} value={selectedMechanicGroup ? selectedMechanicGroup.id : null}  >
                                                                    <option selected value={null}>

                                                                    </option>
                                                                    {listMechanicGroup.data && listMechanicGroup.data.map((mechanicGroup, index) => (
                                                                        <option key={index} value={mechanicGroup.id}>{mechanicGroup.group_name} ({mechanicGroup.list_staff.length > 0 ?
                                                                            (mechanicGroup.list_staff.find(p => p.is_leader === true) ? mechanicGroup.list_staff.find(p => p.is_leader === true).staff_name : "Pimpinan Tidak Tersedia") : "Pimpinan Tidak Tersedia"})</option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                        <div style={{ margin: '10px 0', width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                            <Button variant="primary"
                                                                onClick={() => {
                                                                    setIsAddNewCrewModalVisible(true);
                                                                }}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flex: 1,
                                                                    alignContent: "center",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    flexDirection: "row",
                                                                    alignSelf: "center",
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignContent: "center",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        alignSelf: "center",
                                                                        flex: 1,
                                                                    }}><FilePlusFill size={25} /><div style={{ marginLeft: '10px' }}></div>{'Tambah Anggota'}</div>
                                                                </div>
                                                            </Button>
                                                            <div style={{ margin: '0px 10px' }}></div>
                                                            {
                                                                isEditDetail === true ? (<>
                                                                    <Button variant="primary"
                                                                        onClick={() => {
                                                                            setIsEditDetail(false);
                                                                        }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flex: 1,
                                                                            alignContent: "center",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            flexDirection: "row",
                                                                            alignSelf: "center",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                alignSelf: "center",
                                                                                flex: 1,
                                                                            }}><FileCheckFill size={25} /><div style={{ marginLeft: '10px' }}></div>{'Simpan Perubahan'}</div>
                                                                        </div>
                                                                    </Button></>) : (<>
                                                                        <Button variant="primary"
                                                                            onClick={() => {
                                                                                setIsEditDetail(true);
                                                                            }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flex: 1,
                                                                                alignContent: "center",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                flexDirection: "row",
                                                                                alignSelf: "center",
                                                                            }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    alignContent: "center",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    alignSelf: "center",
                                                                                    flex: 1,
                                                                                }}><PencilFill size={25} /><div style={{ marginLeft: '10px' }}></div>{'Perbaharui Detail'}</div>
                                                                            </div>
                                                                        </Button></>)
                                                            }
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}>
                                                            {
                                                                // selectedMechanicGroup &&
                                                                //     <div style={{
                                                                //         display: "flex",
                                                                //         flexDirection: "column",

                                                                //     }}>
                                                                //         <div style={{
                                                                //             display: "flex",
                                                                //             flexDirection: "row",
                                                                //         }}>
                                                                //             <div style={{
                                                                //                 display: "flex",
                                                                //                 flexDirection: "column",
                                                                //                 padding: 15
                                                                //             }}><p>Nama Pimpinan</p></div>
                                                                //             <div style={{
                                                                //                     display: "flex",
                                                                //                     flexDirection: "column",
                                                                //                     padding: 15
                                                                //                 }}>
                                                                //                     <Form.Check disabled={false} defaultChecked={selectedMechanicGroup.list_staff.find(p => p.is_leader === true).is_selected}  onClick={(e) => {
                                                                //                         let crew = engineHealthInspectionDetail.crew;
                                                                //                         let leader = selectedMechanicGroup.list_staff.find(p => p.is_leader === true);
                                                                //                         if (e.target.checked) {
                                                                //                             // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = true;
                                                                //                             // crew.push(mechanic);
                                                                //                             // changeSelectedCrewOnListStaff(mechanic.id)
                                                                //                             //changeSelectedCrew(mechanic.id, true);
                                                                //                             // console.log(mechanic.is_selected);
                                                                //                             crew.find(p=>p.staff_id === leader.staff_id).is_selected = true;
                                                                //                         } else {
                                                                //                             // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = false;
                                                                //                             // let mecIdx = crew.findIndex(p=>p.staff_id === mechanic.staff_id);
                                                                //                             // if (mecIdx !== -1){
                                                                //                             //     crew.splice(mecIdx, 1);
                                                                //                             // }

                                                                //                             crew.find(p=>p.staff_id === leader.staff_id).is_selected = false;
                                                                //                             //changeSelectedCrew(mechanic.id, false);
                                                                //                         }
                                                                //                         // setSelectedMechanicGroup({...selectedMechanicGroup, list_staff: listGroupStaff});
                                                                //                         setEngineHealthInspectionDetail({...engineHealthInspectionDetail, crew: crew});
                                                                //                     }}>
                                                                //                     </Form.Check>
                                                                //                 </div>
                                                                //             <div style={{
                                                                //                 display: "flex",
                                                                //                 flexDirection: "column",
                                                                //                 padding: 15
                                                                //             }}>{(selectedMechanicGroup.list_staff.length > 0 ?
                                                                //                 (selectedMechanicGroup.list_staff.find(p => p.is_leader === true) ? selectedMechanicGroup.list_staff.find(p => p.is_leader === true).staff_name : "Pimpinan Tidak Tersedia") : "Pimpinan Tidak Tersedia")}</div>
                                                                //             {/* <div style={{
                                                                //                 display: "flex",
                                                                //                 flexDirection: "column",
                                                                //                 padding: 15
                                                                //             }}><Form.Check type="switch" checked={(selectedMechanicGroup.list_staff.find(p => p.is_leader === true).assigned ? 
                                                                //                     selectedMechanicGroup.list_staff.find(p => p.is_leader === true).assigned : false)} onClick={(e) => {
                                                                //                 if (e.target.checked) {
                                                                //                     setSearchProjectParameters({ ...selectedMechanicGroup, isProjectNameChecked: true })
                                                                //                 } else {
                                                                //                     setSearchProjectParameters({ ...searchProjectParameters, isProjectNameChecked: false, projectName: "" })
                                                                //                 }
                                                                //             }}></Form.Check></div> */}
                                                                //         </div>

                                                                //     </div>
                                                            }
                                                            {/* {selectedMechanicGroup && selectedMechanicGroup.list_staff.filter(p => !p.is_leader).map((mechanic, idx) => {
                                                                return (
                                                                    <div key={idx} style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                padding: 15
                                                                            }}><p>Nama Anggota</p></div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                padding: 15
                                                                            }}>
                                                                                <Form.Check disabled={false} defaultChecked={mechanic.is_selected}  onClick={(e) => {
                                                                                    let crew = engineHealthInspectionDetail.crew;
                                                                                    let listGroupStaff = selectedMechanicGroup.list_staff;
                                                                                    if (e.target.checked) {
                                                                                        // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                        // crew.push(mechanic);
                                                                                        // changeSelectedCrewOnListStaff(mechanic.id)
                                                                                        //changeSelectedCrew(mechanic.id, true);
                                                                                        // console.log(mechanic.is_selected);
                                                                                        crew.find(p=>p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                    } else {
                                                                                        // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                        // let mecIdx = crew.findIndex(p=>p.staff_id === mechanic.staff_id);
                                                                                        // if (mecIdx !== -1){
                                                                                        //     crew.splice(mecIdx, 1);
                                                                                        // }

                                                                                        crew.find(p=>p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                        //changeSelectedCrew(mechanic.id, false);
                                                                                    }
                                                                                    // setSelectedMechanicGroup({...selectedMechanicGroup, list_staff: listGroupStaff});
                                                                                    setEngineHealthInspectionDetail({...engineHealthInspectionDetail, crew: crew});
                                                                                }}>
                                                                                </Form.Check>
                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                padding: 15
                                                                            }}>{mechanic.staff_name}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })

                                                            } */}

                                                            {/* {
                                                            engineHealthInspectionDetail && engineHealthInspectionDetail.crew.filter(p => p.is_leader).map((mechanic, idx) => {
                                                                return (
                                                                    <div key={idx} style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                padding: 15
                                                                            }}><p>Nama Pimpinan</p></div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                padding: 15
                                                                            }}>
                                                                                <Form.Check disabled={false} defaultChecked={mechanic.is_selected} onClick={(e) => {
                                                                                    let crew = engineHealthInspectionDetail.crew;
                                                                                    let listGroupStaff = selectedMechanicGroup.list_staff;
                                                                                    if (e.target.checked) {
                                                                                        // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                        // crew.push(mechanic);
                                                                                        // changeSelectedCrewOnListStaff(mechanic.id)
                                                                                        //changeSelectedCrew(mechanic.id, true);
                                                                                        // console.log(mechanic.is_selected);
                                                                                        crew.find(p => p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                    } else {
                                                                                        // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                        // let mecIdx = crew.findIndex(p=>p.staff_id === mechanic.staff_id);
                                                                                        // if (mecIdx !== -1){
                                                                                        //     crew.splice(mecIdx, 1);
                                                                                        // }

                                                                                        crew.find(p => p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                        //changeSelectedCrew(mechanic.id, false);
                                                                                    }
                                                                                    // setSelectedMechanicGroup({...selectedMechanicGroup, list_staff: listGroupStaff});
                                                                                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                }}>
                                                                                </Form.Check>
                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                padding: 15
                                                                            }}>{mechanic.staff_name}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })

                                                            }

                                                            {
                                                            engineHealthInspectionDetail && engineHealthInspectionDetail.crew.filter(p => !p.is_leader).map((mechanic, idx) => {
                                                                return (
                                                                    <div key={idx} style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                    }}>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                padding: 15
                                                                            }}><p>Nama Anggota</p></div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                padding: 15
                                                                            }}>
                                                                                <Form.Check disabled={false} defaultChecked={mechanic.is_selected} onClick={(e) => {
                                                                                    let crew = engineHealthInspectionDetail.crew;
                                                                                    let listGroupStaff = selectedMechanicGroup.list_staff;
                                                                                    if (e.target.checked) {
                                                                                        // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                        // crew.push(mechanic);
                                                                                        // changeSelectedCrewOnListStaff(mechanic.id)
                                                                                        //changeSelectedCrew(mechanic.id, true);
                                                                                        // console.log(mechanic.is_selected);
                                                                                        crew.find(p => p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                    } else {
                                                                                        // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                        // let mecIdx = crew.findIndex(p=>p.staff_id === mechanic.staff_id);
                                                                                        // if (mecIdx !== -1){
                                                                                        //     crew.splice(mecIdx, 1);
                                                                                        // }

                                                                                        crew.find(p => p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                        //changeSelectedCrew(mechanic.id, false);
                                                                                    }
                                                                                    // setSelectedMechanicGroup({...selectedMechanicGroup, list_staff: listGroupStaff});
                                                                                    setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                }}>
                                                                                </Form.Check>
                                                                            </div>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                padding: 15
                                                                            }}>{mechanic.staff_name}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                            } */}

                                                            <div className="table-container">
                                                                <div className="table-header">
                                                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} className="table-header-content">#</div>
                                                                    <div style={{ flex: 3 }} className="table-header-content">Pimpinan</div>
                                                                    <div style={{ flex: 2 }} className="table-header-content">Tanggal Tiba</div>
                                                                    <div style={{ flex: 2 }} className="table-header-content">Tanggal Turun</div>
                                                                    <div style={{ flex: 2 }} className="table-header-content">Tiba Dari</div>
                                                                    <div style={{ flex: 2 }} className="table-header-content">Group Teknisi</div>
                                                                    <div style={{ flex: 1 }} className="table-header-content">Status</div>
                                                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                                                </div>
                                                                {
                                                                    engineHealthInspectionDetail && engineHealthInspectionDetail.crew.filter(crew => crew.is_leader).map((mechanic, index) => {
                                                                        return (

                                                                            <div className="table-body">
                                                                                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} className="table-body-content"><p>{(index + 1)}</p></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{mechanic.staff_name}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content">
                                                                                    <Form.Control style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        width: "100%",
                                                                                        flex: 1,
                                                                                    }} disabled={!isEditDetail} required type="date" onChange={(e) => {
                                                                                        let crew = engineHealthInspectionDetail.crew;
                                                                                        crew.find(crew => crew.staff_id === mechanic.staff_id).arrived_at = moment(e.target.value).local().format("YYYY-MM-DD");
                                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                    }} value={mechanic.arrived_at ? moment(mechanic.arrived_at).local().format("YYYY-MM-DD") : ""}></Form.Control>
                                                                                </div>
                                                                                <div style={{ flex: 2 }} className="table-body-content">
                                                                                    <Form.Control style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        width: "100%",
                                                                                        flex: 1,
                                                                                    }} disabled={!isEditDetail} required type="date" onChange={(e) => {
                                                                                        let crew = engineHealthInspectionDetail.crew;
                                                                                        crew.find(crew => crew.staff_id === mechanic.staff_id).departure_date = moment(e.target.value).local().format("YYYY-MM-DD");
                                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                    }} value={mechanic.departure_date ? moment(mechanic.departure_date).local().format("YYYY-MM-DD") : ""}></Form.Control>
                                                                                </div>
                                                                                <div style={{ flex: 2 }} className="table-body-content">
                                                                                    <Form.Control style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        width: "100%",
                                                                                        flex: 1,
                                                                                    }} disabled={!isEditDetail} required type="text" onChange={(e) => {
                                                                                        let crew = engineHealthInspectionDetail.crew;
                                                                                        crew.find(crew => crew.staff_id === mechanic.staff_id).arrived_from = e.target.value;
                                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                    }} value={mechanic.arrived_from ? mechanic.arrived_from : ""}></Form.Control>
                                                                                </div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{selectedMechanicGroup && selectedMechanicGroup.id === mechanic.mechanic_group_id ? selectedMechanicGroup.group_name : ""}</p></div>
                                                                                <div style={{ flex: 1 }} className="table-body-content">
                                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                                                        <Form.Check disabled={false} defaultChecked={mechanic.is_selected} onClick={(e) => {
                                                                                            let crew = engineHealthInspectionDetail.crew;
                                                                                            let listGroupStaff = selectedMechanicGroup.list_staff;
                                                                                            if (e.target.checked) {
                                                                                                // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                                // crew.push(mechanic);
                                                                                                // changeSelectedCrewOnListStaff(mechanic.id)
                                                                                                //changeSelectedCrew(mechanic.id, true);
                                                                                                // console.log(mechanic.is_selected);
                                                                                                crew.find(p => p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                            } else {
                                                                                                // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                                // let mecIdx = crew.findIndex(p=>p.staff_id === mechanic.staff_id);
                                                                                                // if (mecIdx !== -1){
                                                                                                //     crew.splice(mecIdx, 1);
                                                                                                // }

                                                                                                crew.find(p => p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                                //changeSelectedCrew(mechanic.id, false);
                                                                                            }
                                                                                            // setSelectedMechanicGroup({...selectedMechanicGroup, list_staff: listGroupStaff});
                                                                                            setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                        }}>
                                                                                        </Form.Check>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ flex: 1 }} className="table-body-content">
                                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                                                        <Button variant="danger" disabled={selectedMechanicGroup && selectedMechanicGroup.id === mechanic.mechanic_group_id ? true : false} onClick={() => {
                                                                                            // removeSelectedCrew(mechanic.staff_id);
                                                                                        }}><Trash />
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        )
                                                                    })


                                                                }
                                                            </div>

                                                            <div style={{ width: '100%', margin: '10px' }}></div>

                                                            <div className="table-container">
                                                                <div className="table-header">
                                                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} className="table-header-content">#</div>
                                                                    <div style={{ flex: 3 }} className="table-header-content">Anggota</div>
                                                                    <div style={{ flex: 2 }} className="table-header-content">Tanggal Tiba</div>
                                                                    <div style={{ flex: 2 }} className="table-header-content">Tanggal Turun</div>
                                                                    <div style={{ flex: 2 }} className="table-header-content">Tiba Dari</div>
                                                                    <div style={{ flex: 2 }} className="table-header-content">Group Teknisi</div>
                                                                    <div style={{ flex: 1 }} className="table-header-content">Status</div>
                                                                    <div style={{ flex: 1 }} className="table-header-content">Hapus</div>
                                                                </div>
                                                                {
                                                                    engineHealthInspectionDetail && engineHealthInspectionDetail.crew.filter(crew => !crew.is_leader).map((mechanic, index) => {
                                                                        return (

                                                                            <div className="table-body">
                                                                                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} className="table-body-content"><p>{(index + 1)}</p></div>
                                                                                <div style={{ flex: 3 }} className="table-body-content"><p>{mechanic.staff_name}</p></div>
                                                                                <div style={{ flex: 2 }} className="table-body-content">
                                                                                    <Form.Control style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        width: "100%",
                                                                                        flex: 1,
                                                                                    }} disabled={!isEditDetail} required type="date" onChange={(e) => {
                                                                                        let crew = engineHealthInspectionDetail.crew;
                                                                                        crew.find(crew => crew.staff_id === mechanic.staff_id).arrived_at = moment(e.target.value).local().format("YYYY-MM-DD");
                                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                    }} value={mechanic.arrived_at ? moment(mechanic.arrived_at).local().format("YYYY-MM-DD") : ""}></Form.Control>
                                                                                </div>
                                                                                <div style={{ flex: 2 }} className="table-body-content">
                                                                                    <Form.Control style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        width: "100%",
                                                                                        flex: 1,
                                                                                    }} disabled={!isEditDetail} required type="date" onChange={(e) => {
                                                                                        let crew = engineHealthInspectionDetail.crew;
                                                                                        crew.find(crew => crew.staff_id === mechanic.staff_id).departure_date = moment(e.target.value).local().format("YYYY-MM-DD");
                                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                    }} value={mechanic.arrived_at ? moment(mechanic.departure_date).local().format("YYYY-MM-DD") : ""}></Form.Control>
                                                                                </div>
                                                                                <div style={{ flex: 2 }} className="table-body-content">
                                                                                    <Form.Control style={{
                                                                                        display: "flex",
                                                                                        flexDirection: "column",
                                                                                        width: "100%",
                                                                                        flex: 1,
                                                                                    }} disabled={!isEditDetail} required type="text" onChange={(e) => {
                                                                                        let crew = engineHealthInspectionDetail.crew;
                                                                                        crew.find(crew => crew.staff_id === mechanic.staff_id).arrived_from = e.target.value;
                                                                                        setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                    }} value={mechanic.arrived_from ? mechanic.arrived_from : ""}></Form.Control>
                                                                                </div>
                                                                                <div style={{ flex: 2 }} className="table-body-content"><p>{selectedMechanicGroup && selectedMechanicGroup.id === mechanic.mechanic_group_id ? selectedMechanicGroup.group_name : ""}</p></div>
                                                                                <div style={{ flex: 1 }} className="table-body-content">
                                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                                                        <Form.Check disabled={false} defaultChecked={mechanic.is_selected} onClick={(e) => {
                                                                                            let crew = engineHealthInspectionDetail.crew;
                                                                                            let listGroupStaff = selectedMechanicGroup.list_staff;
                                                                                            if (e.target.checked) {
                                                                                                // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                                // crew.push(mechanic);
                                                                                                // changeSelectedCrewOnListStaff(mechanic.id)
                                                                                                //changeSelectedCrew(mechanic.id, true);
                                                                                                // console.log(mechanic.is_selected);
                                                                                                crew.find(p => p.staff_id === mechanic.staff_id).is_selected = true;
                                                                                            } else {
                                                                                                // listGroupStaff.find(p=>p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                                // let mecIdx = crew.findIndex(p=>p.staff_id === mechanic.staff_id);
                                                                                                // if (mecIdx !== -1){
                                                                                                //     crew.splice(mecIdx, 1);
                                                                                                // }

                                                                                                crew.find(p => p.staff_id === mechanic.staff_id).is_selected = false;
                                                                                                //changeSelectedCrew(mechanic.id, false);
                                                                                            }
                                                                                            // setSelectedMechanicGroup({...selectedMechanicGroup, list_staff: listGroupStaff});
                                                                                            setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                                        }}>
                                                                                        </Form.Check>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ flex: 1 }} className="table-body-content">
                                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                                                                        <Button variant="danger" disabled={selectedMechanicGroup && selectedMechanicGroup.id === mechanic.mechanic_group_id ? true : !isEditDetail} onClick={() => {
                                                                                            removeSelectedCrew(mechanic.staff_id);
                                                                                        }}><Trash />
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        )
                                                                    })

                                                                }
                                                            </div>

                                                            {
                                                                // listSelectedStaffForNewCrew && listSelectedStaffForNewCrew.map((mechanic, idx) => {
                                                                //     return (
                                                                //         <div key={idx} style={{
                                                                //             display: "flex",
                                                                //             flexDirection: "column",
                                                                //         }}>
                                                                //             <div style={{
                                                                //                 display: "flex",
                                                                //                 flexDirection: "row",
                                                                //             }}>
                                                                //                 <div style={{
                                                                //                     display: "flex",
                                                                //                     flexDirection: "column",
                                                                //                     padding: 15
                                                                //                 }}><p>Nama Anggota</p></div>
                                                                //                 <div style={{
                                                                //                     display: "flex",
                                                                //                     flexDirection: "column",
                                                                //                     padding: 15
                                                                //                 }}>
                                                                //                     <Form.Check disabled={false} defaultChecked={mechanic.is_selected} onClick={(e) => {
                                                                //                         let crew = engineHealthInspectionDetail.crew;
                                                                //                         // let listGroupStaff = selectedStaffForNewCrew;
                                                                //                         if (e.target.checked) {
                                                                //                             // listGroupStaff.find(p => p.staff_id === mechanic.staff_id).is_selected = true;
                                                                //                             mechanic.is_selected = true;
                                                                //                             crew.push(mechanic);
                                                                //                             // changeSelectedCrewOnListStaff(mechanic.id)
                                                                //                             //changeSelectedCrew(mechanic.id, true);
                                                                //                             // console.log(mechanic.is_selected);
                                                                //                         } else {
                                                                //                             // listGroupStaff.find(p => p.staff_id === mechanic.staff_id).is_selected = false;
                                                                //                             mechanic.is_selected = false;
                                                                //                             let mecIdx = crew.findIndex(p => p.staff_id === mechanic.staff_id);
                                                                //                             if (mecIdx !== -1) {
                                                                //                                 crew.splice(mecIdx, 1);
                                                                //                             }
                                                                //                             //changeSelectedCrew(mechanic.id, false);
                                                                //                         }
                                                                //                         // setSelectedMechanicGroup({ ...selectedMechanicGroup, list_staff: listGroupStaff });
                                                                //                         setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, crew: crew });
                                                                //                     }}>
                                                                //                     </Form.Check>
                                                                //                 </div>
                                                                //                 <div style={{
                                                                //                     display: "flex",
                                                                //                     flexDirection: "column",
                                                                //                     padding: 15
                                                                //                 }}>{mechanic.staff_name}</div>
                                                                //             </div>
                                                                //         </div>
                                                                //     );
                                                                // })

                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            }>
                                        </ContainerBox>
                                        <div style={{
                                            paddingTop: 20
                                        }} ></div>
                                        <ContainerBox containerPos="inner"
                                            titleCaption={"Detail Pekerjaan"}
                                            useActionContainer={true}
                                            actionContainerChild={
                                                <div>
                                                    {/* <Button variant="secondary"
                                                        onClick={() => {
                                                            // setAddingNewInspectionDetailButton(true);
                                                            addNewInspectionDetail();
                                                        }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "row",
                                                            alignSelf: "center",
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignSelf: "center",
                                                                flex: 1,
                                                            }}><FilePlusFill size={32} /> {'Tambah Pekerjaan'} </div>
                                                        </div>
                                                    </Button> */}
                                                </div>
                                            }
                                            childContent={
                                                <div>
                                                    {
                                                        listInspectionDetail && listInspectionDetail.map((detail, index) => {
                                                            if (detail.is_active === true){
                                                                return (
                                                                    <div key={index}>
                                                                        <EngineHealthInspectionDetail
                                                                            inspectionDetail={detail}
                                                                            index={index}
                                                                            updateDetail={updateListInspectionDetail}
                                                                            vesselId={engineHealthInspectionDetail.vessel_id}
                                                                            vesselEquipmentId={engineHealthInspectionDetail.vessel_equipment_id}
                                                                            engineHealthInspectionId={engineHealthInspectionDetail.engine_health_inspection_id}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    <div style={{
                                                        paddingLeft: 15,
                                                        paddingRight: 15,
                                                        paddingBottom: 20,
                                                        paddingTop: 20
                                                    }}>
                                                        <Button style={{ width: '100%' }} variant="primary"
                                                            onClick={() => {
                                                                addNewInspectionDetail();
                                                            }}>
                                                                <div style={{
                                                            display: "flex",
                                                            flex: 1,
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "row",
                                                            alignSelf: "center",
                                                            width: '100%'
                                                        }}>
                                                            <div style={{
                                                                display: "flex",
                                                                alignContent: "center",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                alignSelf: "center",
                                                                flex: 1,
                                                            }}><FilePlusFill size={32} /> <div style={{ marginLeft: '10px' }}></div> {'Tambah Pekerjaan'} </div>
                                                        </div>
                                                        </Button>
                                                    </div>
                                                </div>
                                            }>
                                        </ContainerBox>
                                        {/* </>) : (<></>)
                                        } */}
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                paddingLeft: 15,
                                paddingRight: 15,
                                paddingBottom: 20,
                            }}>
                                <Button style={{ width: '100%' }} variant="primary" disabled={isSaveButtonDisabled}
                                    onClick={() => {
                                        if (window.confirm("Apakah Anda ingin menyimpan data inspeksi?")) {
                                            setLoading(true);
                                            setEngineHealthInspectionDetail({ ...engineHealthInspectionDetail, shouldUpdateToAPI: true, isFinishedLoading: false });
                                        }
                                    }}>
                                    {'SIMPAN DATA INSPEKSI'}
                                </Button>
                            </div>
                        </div>

                    }>

                </ContainerBox>

            </Container>
            <Loading
                loading={loading}
            />
            {
                /* Project Modal */
                <Modal size="xl" show={isSelectProjectModalVisible} onHide={
                    () => {
                        setIsSelectProjectModalVisible(false);
                    }
                }>
                    <ContainerBox containerPos="inner" titleCaption={"Cari Proyek"} useActionContainer={true}
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flex: 1,
                                        flexDirection: "row",
                                        padding: 5
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nama Proyek</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchProjectParameters.isProjectNameChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchProjectParameters({ ...searchProjectParameters, isProjectNameChecked: true })
                                                    } else {
                                                        setSearchProjectParameters({ ...searchProjectParameters, isProjectNameChecked: false, projectName: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchProjectParameters.isProjectNameChecked} type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchProjectSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchProjectParameters({ ...searchProjectParameters, projectName: e.target.value })
                                                    }} value={searchProjectParameters.projectName}></Form.Control>
                                            </div>
                                        </Form.Group>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nama Kapal</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchProjectParameters.isVesselNameChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchProjectParameters({ ...searchProjectParameters, isVesselNameChecked: true })
                                                    } else {
                                                        setSearchProjectParameters({ ...searchProjectParameters, isVesselNameChecked: false, vesselName: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchProjectParameters.isVesselNameChecked} required type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchProjectSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchProjectParameters({ ...searchProjectParameters, vesselName: e.target.value })
                                                    }} value={searchProjectParameters.vesselName}></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flex: 1,
                                        flexDirection: "row",
                                        padding: 5
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nama Pelanggan</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchProjectParameters.isCustomerNameChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchProjectParameters({ ...searchProjectParameters, isCustomerNameChecked: true })
                                                    } else {
                                                        setSearchProjectParameters({ ...searchProjectParameters, isCustomerNameChecked: false, customerName: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchProjectParameters.isCustomerNameChecked} type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchProjectSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchProjectParameters({ ...searchProjectParameters, customerName: e.target.value })
                                                    }} value={searchProjectParameters.customerName}></Form.Control>
                                            </div>
                                        </Form.Group>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nomor Proyek</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchProjectParameters.isProjectNumberChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchProjectParameters({ ...searchProjectParameters, isProjectNumberChecked: true })
                                                    } else {
                                                        setSearchProjectParameters({ ...searchProjectParameters, isProjectNumberChecked: false, projectNumber: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchProjectParameters.isProjectNumberChecked} required type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchProjectSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchProjectParameters({ ...searchProjectParameters, projectNumber: e.target.value })
                                                    }} value={searchProjectParameters.projectNumber}></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <Button style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }} variant="primary" onClick={() => {
                                            onSearchProjectSubmit();
                                        }}>Cari</Button>
                                        <div style={{ paddingRight: 10, paddingLeft: 10 }}></div>
                                        <Button style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }} variant="primary" onClick={() => {
                                            setListProject({ data: [], isFinishedLoading: true })
                                        }}>Reset</Button>
                                    </div>
                                    <div>
                                        {!isProjectLoading && listProject.isFinishedLoading && listProject.data.map((project, idx) => {
                                            return (
                                                <div key={idx} style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    borderStyle: "solid",
                                                    borderWidth: 1,
                                                    margin: 5,
                                                    borderRadius: 10,
                                                    padding: 5
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 8
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>{project.project_number} : {project.project_name}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Pelanggan: {project.customer_name}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Nama Kapal: {project.vessel_name}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Tanggal Mulai: {project.project_start_date}</div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}>
                                                        <Button style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }} variant="primary" onClick={() => {
                                                            setIsSelectProjectModalVisible(false);
                                                            setSelectedProject(project);
                                                        }}>Pilih Proyek</Button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {isProjectLoading &&
                                            <div style={{
                                                flex: 2,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginTop: 80,
                                            }}>
                                                <Spinner
                                                    variant="info" animation="border" role="status" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        } />
                </Modal>
            }
            {
                /* Project Modal */
                <Modal size="xl" show={isSelectStaffModalVisible} onHide={
                    () => {
                        setIsSelectStaffModalVisible(false);
                    }
                }>
                    <ContainerBox containerPos="inner" titleCaption={"Cari Staff"} useActionContainer={true}
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flex: 1,
                                        flexDirection: "row",
                                        padding: 5
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nama Karyawan</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchStaffParameters.isStaffNameChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchStaffParameters({ ...searchStaffParameters, isStaffNameChecked: true })
                                                    } else {
                                                        setSearchStaffParameters({ ...searchStaffParameters, isStaffNameChecked: false, staffName: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchStaffParameters.isStaffNameChecked} type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchStaffSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchStaffParameters({ ...searchStaffParameters, staffName: e.target.value })
                                                    }} value={searchStaffParameters.staffName}></Form.Control>
                                            </div>
                                        </Form.Group>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nomor Induk Karyawan</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchStaffParameters.isEmployeeNumberChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchStaffParameters({ ...searchStaffParameters, isEmployeeNumberChecked: true })
                                                    } else {
                                                        setSearchStaffParameters({ ...searchStaffParameters, isEmployeeNumberChecked: false, employeeNumber: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchStaffParameters.isEmployeeNumberChecked} required type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchStaffSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchStaffParameters({ ...searchStaffParameters, employeeNumber: e.target.value })
                                                    }} value={searchStaffParameters.employeeNumber}></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flex: 1,
                                        flexDirection: "row",
                                        padding: 5
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nama Divisi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchStaffParameters.isStaffDivisionChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchStaffParameters({ ...searchStaffParameters, isStaffDivisionChecked: true })
                                                    } else {
                                                        setSearchStaffParameters({ ...searchStaffParameters, isStaffDivisionChecked: false, customerName: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchStaffParameters.isStaffDivisionChecked} type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchStaffSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchStaffParameters({ ...searchStaffParameters, staffDivision: e.target.value })
                                                    }} value={searchStaffParameters.staffDivision}></Form.Control>
                                            </div>
                                        </Form.Group>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3"></div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <Button style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }} variant="primary" onClick={() => {
                                            onSearchStaffSubmit();
                                        }}>Cari</Button>
                                        <div style={{ paddingRight: 10, paddingLeft: 10 }}></div>
                                        <Button style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }} variant="primary" onClick={() => {
                                            setListStaff({ data: [], isFinishedLoading: true })
                                        }}>Reset</Button>
                                    </div>
                                    <div>
                                        {!isStaffLoading && listStaff.isFinishedLoading && listStaff.data.map((staff, idx) => {
                                            return (
                                                <div key={idx} style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    borderStyle: "solid",
                                                    borderWidth: 1,
                                                    margin: 5,
                                                    borderRadius: 10,
                                                    padding: 5
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 8
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>NIK: {staff.employee_number}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Nama: {staff.staff_name}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Divisi: {staff.division_name}</div>
                                                        {/* <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Tanggal Mulai: {project.project_start_date}</div> */}
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}>
                                                        <Button style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }} variant="primary" onClick={() => {
                                                            setIsSelectStaffModalVisible(false);
                                                            setSelectedStaff(staff);
                                                        }}>Pilih Karyawan</Button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {isStaffLoading &&
                                            <div style={{
                                                flex: 2,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginTop: 80,
                                            }}>
                                                <Spinner
                                                    variant="info" animation="border" role="status" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        } />
                </Modal>
            }
            {
                /* Engine Modal */
                <Modal size="xl" show={isSelectEngineModalVisible} onHide={
                    () => {
                        setIsSelectEngineModalVisible(false);
                        setLoading(false);
                    }
                }>
                    <ContainerBox containerPos="inner" titleCaption={"Pilih Mesin"} useActionContainer={true}
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <div>
                                        {!isEngineLoading && listEngine.isFinishedLoading && listEngine.data.ME.map((engine, idx) => {
                                            return (
                                                <div key={idx} style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    borderStyle: "solid",
                                                    borderWidth: 1,
                                                    margin: 5,
                                                    borderRadius: 10,
                                                    padding: 5
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 8
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}><h5>Main Engine</h5></div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>{engine.title}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Serial Number: {engine.serial_number}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Position: {engine.equipment_position}</div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}>
                                                        <Button style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }} variant="primary" onClick={() => {
                                                            setIsEngineLoading(false);
                                                            setSelectedEngine(engine);
                                                        }}>Pilih Mesin</Button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {!isEngineLoading && listEngine.isFinishedLoading && listEngine.data.AE.map((engine, idx) => {
                                            return (
                                                <div key={idx} style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    borderStyle: "solid",
                                                    borderWidth: 1,
                                                    margin: 5,
                                                    borderRadius: 10,
                                                    padding: 5
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 8
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}><h5>Auxiliary Engine</h5></div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>{engine.title}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Serial Number: {engine.serial_number}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Position: {engine.equipment_position}</div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}>
                                                        <Button style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }} variant="primary" onClick={() => {
                                                            setIsEngineLoading(false);
                                                            setSelectedEngine(engine);
                                                        }}>Pilih Mesin</Button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {!isEngineLoading && listEngine.isFinishedLoading && listEngine.data.ME.length === 0 &&
                                            listEngine.data.AE.length === 0 &&
                                            <div style={{
                                                flex: 2,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginTop: 80,
                                            }}>
                                                <div>Data Mesin Tidak Ditemukan</div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        } />
                </Modal>
            }

            {
                /* Add new crew from outside mechanic group Modal */
                <Modal size="xl" show={isAddNewCrewModalVisible} onHide={
                    () => {
                        setIsAddNewCrewModalVisible(false);
                    }
                }>
                    <ContainerBox containerPos="inner" titleCaption={"Cari Anggota"} useActionContainer={true}
                        childContent={
                            <div style={{
                                padding: 10,
                            }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    flexDirection: "column",
                                    padding: 5
                                }}>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flex: 1,
                                        flexDirection: "row",
                                        padding: 5
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nama Karyawan</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchStaffForNewCrew.isStaffNameChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchStaffForNewCrew({ ...searchStaffForNewCrew, isStaffNameChecked: true })
                                                    } else {
                                                        setSearchStaffForNewCrew({ ...searchStaffForNewCrew, isStaffNameChecked: false, staffName: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchStaffForNewCrew.isStaffNameChecked} type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchStaffForCrewSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchStaffForNewCrew({ ...searchStaffForNewCrew, staffName: e.target.value })
                                                    }} value={searchStaffForNewCrew.staffName}></Form.Control>
                                            </div>
                                        </Form.Group>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nomor Induk Karyawan</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchStaffForNewCrew.isEmployeeNumberChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchStaffForNewCrew({ ...searchStaffForNewCrew, isEmployeeNumberChecked: true })
                                                    } else {
                                                        setSearchStaffForNewCrew({ ...searchStaffForNewCrew, isEmployeeNumberChecked: false, employeeNumber: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchStaffForNewCrew.isEmployeeNumberChecked} required type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchStaffForCrewSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchStaffForNewCrew({ ...searchStaffForNewCrew, employeeNumber: e.target.value })
                                                    }} value={searchStaffForNewCrew.employeeNumber}></Form.Control>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "100%",
                                        flex: 1,
                                        flexDirection: "row",
                                        padding: 5
                                    }}>
                                        <Form.Group style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3">
                                            <Form.Label>Nama Divisi</Form.Label>
                                            <div style={{
                                                display: "flex",
                                                width: "100%",
                                                flexDirection: "row",
                                                padding: 5
                                            }}>
                                                <Form.Check type="switch" checked={searchStaffForNewCrew.isStaffDivisionChecked} onClick={(e) => {
                                                    if (e.target.checked) {
                                                        setSearchStaffForNewCrew({ ...searchStaffForNewCrew, isStaffDivisionChecked: true })
                                                    } else {
                                                        setSearchStaffForNewCrew({ ...searchStaffForNewCrew, isStaffDivisionChecked: false, customerName: "" })
                                                    }
                                                }}></Form.Check>
                                                <div style={{ paddingLeft: 20 }}></div>
                                                <Form.Control disabled={!searchStaffForNewCrew.isStaffDivisionChecked} type="text"
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 13) {
                                                            onSearchStaffForCrewSubmit();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        setSearchStaffForNewCrew({ ...searchStaffForNewCrew, staffDivision: e.target.value })
                                                    }} value={searchStaffForNewCrew.staffDivision}></Form.Control>
                                            </div>
                                        </Form.Group>
                                        <div style={{
                                            display: "flex",
                                            width: "100%",
                                            flexDirection: "column",
                                            padding: 5
                                        }} className="mb-3"></div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <Button style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }} variant="primary" onClick={() => {
                                            onSearchStaffSubmit();
                                        }}>Cari</Button>
                                        <div style={{ paddingRight: 10, paddingLeft: 10 }}></div>
                                        <Button style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                            alignContent: "center",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }} variant="primary" onClick={() => {
                                            setListStaff({ data: [], isFinishedLoading: true })
                                        }}>Reset</Button>
                                    </div>
                                    <div>
                                        {!isStaffForCrewLoading && listStaffForNewCrew.isFinishedLoading && listStaffForNewCrew.data.map((staff, idx) => {
                                            return (
                                                <div key={idx} style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    borderStyle: "solid",
                                                    borderWidth: 1,
                                                    margin: 5,
                                                    borderRadius: 10,
                                                    padding: 5
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 8
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>NIK: {staff.employee_number}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Nama: {staff.staff_name}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Divisi: {staff.division_name}</div>
                                                        {/* <div style={{
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>Tanggal Mulai: {project.project_start_date}</div> */}
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flex: 1,
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}>
                                                        <Button style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            width: "100%",
                                                            alignContent: "center",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }} variant="primary" onClick={() => {
                                                            setIsAddNewCrewModalVisible(false);
                                                            setSelectedStaffForNewCrew(staff);
                                                        }}>Pilih Karyawan</Button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                        {isStaffForCrewLoading &&
                                            <div style={{
                                                flex: 2,
                                                alignContent: "center",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginTop: 80,
                                            }}>
                                                <Spinner
                                                    variant="info" animation="border" role="status" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        } />
                </Modal>


            }
        </>
    )

}